import React, { useState, useEffect } from 'react';
import './App.css';

const BASE_URL = 'https://gf-io-api.com'; // Adjust this to your backend's base URL

function App() {
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetchConnectCheck();
  }, []);

  const fetchConnectCheck = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/carbon/connect-check`);
      const data = await response.json();
      setMessage(data.message);
    } catch (error) {
      console.error('Error fetching connect-check:', error);
      setMessage('Error reaching backend APIs');
    }
  };

  return (
    <div className="App">
      <div class="bubble2"></div>
      <div class="bubble2 bottom-mid"></div>
      <div className="panel glass">
        <header className="App-header glass2">
          <h4>API Connection Check</h4>
          <p className="teal">{message}</p>
        </header>
      </div>

      <div class="bubble bottom-corner"></div>
    </div>
  );
}

export default App;
